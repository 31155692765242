<template>

  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :password="password"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Zoeken op naam of email..."
              />
              <b-button
                variant="primary"
                @click="showAddUserView"
              >
                <span class="text-nowrap">Toevoegen</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        empty-text="overeenkomende records gevonden"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            :to="{ name: 'apps-users-edit', params: { id: data.item.id }}"
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                variant="light-primary"
                :text="avatarText(data.item.fullName)"
                :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
              style="margin-top:10px"
            >
              {{ data.item.fullName }}
            </b-link>
            <!--            <small class="text-muted">{{ data.item.email }}</small>-->
          </b-media>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusName(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <b-link
              :to="{ name: 'app-users-edit', params: { id: data.item.id }}"
              :id="`user-row-${data.item.id}-view-icon`"
            >
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                class="cursor-pointer mx-1"
                icon="EyeIcon"
                size="16"
              />
            </b-link>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { generatePassword } from '@core/utils/utils'
import useUsersList from '../useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from '../users-add/UserListAddNew.vue'

export default {
  components: {
    UserListAddNew,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      password: '',
    }
  },
  methods: {
    showAddUserView() {
      this.password = generatePassword()
      this.isAddNewUserSidebarActive = true
    },
    confirmProductCategoryDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            store.dispatch('apps-users-list/deleteUser', { id })
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Verwijderd!',
                  text: 'De gebruiker is verwijderd.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-users-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      resolveUserStatusVariant,
      refetchData,
      resolveUserStatusName,
      statusOptions,
      showOverlay,
    } = useUsersList()

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      resolveUserStatusVariant,
      refetchData,
      resolveUserStatusName,
      statusOptions,
      showOverlay,
      isAddNewUserSidebarActive,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
