import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      label: '#', key: 'id', sortable: true, tdClass: 'organisation_id',
    },
    { label: 'Naam', key: 'fullName', sortable: true },
    { label: 'Gebruikersnaam', key: 'email', sortable: false },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('apps-users-list/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalUsers.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen gebruikers',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 5) return 'warning'
    if (status === 9) return 'warning'
    if (status === 10) return 'success'
    if (status === 99) return 'warning'
    if (status === 20) return 'warning'
    return 'primary'
  }

  const resolveUserStatusName = status => {
    if (status === 5) return 'Uitgenodigd'
    if (status === 9) return 'Inactief'
    if (status === 10) return 'Actief'
    if (status === 99) return 'Verwijderd'
    if (status === 20) return 'Wachten op activatie'
    return ''
  }

  const statusOptions = [
    { label: 'Actief', value: 10 },
    { label: 'Inactief', value: 9 },
  ]

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    resolveUserStatusVariant,
    refetchData,
    resolveUserStatusName,
    statusOptions,
    showOverlay,
  }
}
